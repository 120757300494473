.owler-ui
{
    @import "../../style/variables";
    @import '../../style/~tachyons-sass/tachyons.scss';
    @import '../../style/mixins';
    @import '../../style/type';

    .owler-topbar
    {
        padding: 10px 0;
        background-color: white;
        display: flex;
        max-width: 1170px;
        align-items: center;

        a
        {
            font-size: 1.5em;
            font-weight: bold;

            display: flex;
            justify-content: space-between;
            align-items: center;
            flex: 1;
            text-decoration: none;

            @include text_headline(black);

            span
            {
                margin: 0;
                padding: 0 10px;
            }
        }

        img {
            width: 300px;
            height: 52px;
        }

        .left-content {
            justify-content: flex-start;       
        }

        .right-content
        {
            justify-content: flex-end;
        }
            


        .contact-button
        {
            justify-content: flex-end;
            font-size: 16px;
            font-weight: bold;

            padding: 4px 30px;

            border: none;
            border-radius: 0 4px 4px 0;
            background-color: #10a3a3;

            @include text_headline($topbar-download-url-button-font-color);
        }
    }

    .swagger-ui {
        max-width: 1170px;
        font-family: Monaco, Menlo, Consolas, "Courier New", monospace !important;
        .auth-wrapper {
            justify-content: flex-start;
            padding: 10px 0 0 0;
        }
        .scheme-container {
            padding: 0;
            margin: 0;
        }
        .info {
            margin: 30px 0 0 0;
        }
        .title {
            font-size: 24px;
            font-weight: 400;
            color: darkslategray;
        }
        .version, .version-stamp {
            font-size: 10px;
        }
    }

    .servers, .servers-title, .url, .models, .response-controls, .response-col_links, .info__extdocs {
        display: none;
    }

    .swagger-ui .opblock-tag {
        font-size: 1.1em;
        font-weight:600;
        padding: 10px 10px 0px 10px;
        margin: 0;
    }

    // Note some of the elements within the code blocks have hard-coded element styles, so using !important to force them
    // without changing the markup
    .swagger-ui pre.microlight {
        margin-bottom: 5px;
        font-family: Monaco, Menlo, Consolas, "Courier New", monospace !important;
        font-weight: 500 !important;
        font-style: italic !important;
        font-size: 0.8em !important;
        color: brown !important;
        background:  #fcf6db !important;
        max-height: 200px;
    }

    .swagger-ui .highlight-code .hljs-attr {
        color:red !important;
        font-size: 0.8em !important;
    }

    .swagger-ui .highlight-code code span {
        color: brown !important;
        font-size: 0.8em !important;
    }

    .swagger-ui .curl span, .swagger-ui .request-url pre {
        color:red !important;
        font-size: 0.8em !important;
    }


    .swagger-ui .btn.authorize {
        line-height: 1;
        display: inline;
        color: #10a3a3;
        border-color: #10a3a3;
        background-color: transparent;
    }
    .swagger-ui .btn.authorize svg {
        fill: #10a3a3;
    }


    .swagger-ui .opblock {

        margin: 0 0 5px;
        font-size: 0.9em;
        background-color: rgba(16, 163, 163, 0.06);

        .opblock-summary {
            padding: 1px;
        }

        .opblock-summary-method {
            font-size: 0.7em;
            color: #fff;
            background-color: #10a3a3;
            padding: 6px 6px;
            min-width: 50px;
        }

        .opblock-summary-path {
            font-size: 1.1em; 
        }

        .opblock-summary {
            display: flex;
            flex-grow:1;
            flex-shrink:1;
            flex-basis:auto;
        }

        .opblock-summary-description {
            color:red;
            text-align: right;
            font-size: 0.9em;
            font-weight: 400;
        }

        opblock-description {
            font-size: 0.7em; 
        }

        .opblock-description-wrapper {
            padding: 1px;
        }

        .response-col_description p, .response-col_status {
            font-family: Monaco, Menlo, Consolas, "Courier New", monospace;
            font-weight: 500;
            font-size: 0.8em; 
            margin-block-start: 0;   
        }

        
        .parameters-col_description {
            font-family: Monaco, Menlo, Consolas, "Courier New", monospace;
            font-weight: 500;
            font-size: 0.8em;    
        }

        table tbody tr td {
            padding: 2px;

        }
        select {
            font-size:0.8em;
            font-weight:700;
            color: #999;
        }

        .execute-wrapper {
            width:200px;
        }

        .btn-group {
            width: 400px;
        }
    }
}
